/**
 * 登錄等頁面，已經登錄後不需要進入
 */
const noAuthPath = ['/login', '/pwdLogin', '/retrievePwd']
export default defineNuxtRouteMiddleware((to, from) => {
  const token = useCookie('token').value
  if (noAuthPath.includes(to.path) && token) {
    return abortNavigation()
  }
})
